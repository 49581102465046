import React from "react"
import Img from "gatsby-image"
import "./media-image.scss";

const MediaImage = (props) => {

  const { attr, fluid } = props;

  return(
    <div className={`media media--image media--align-${attr['data-align'] || 'none'}`}>
      <Img 
        alt={attr.alt}
        fluid={{
        ...fluid
      }} />
      {attr['data-caption'] &&
        <div className="caption">{attr['data-caption']}</div>
      }
    </div>
  )
}

export default MediaImage;

