import React from "react";
import "./recipe-full.scss"
import Img from "gatsby-image";
import Body from "./body";

const RecipeFull = (props) => {

  return (
    <div className="article-full">
      <div className="article-image">
        <h1>{props.entity.entityLabel}</h1>
        <Img alt={props.entity.entityLabel} fluid={{...props.entity.fieldImage.imageGatsbyFile.childImageSharp.fluid, sizes: '(max-width: 400px) 100px, (max-width: 800px) 200px, 800px'}} />
      </div>
      <div className="article-content">
        <Body className="article-body">{props.entity.fieldDescription.value}</Body>
      </div>
    </div>
  )
};

export default RecipeFull;
