import React from "react";
import YouTube from 'react-youtube';
import url from 'url';
import "./media-remote-video.scss";

const MediaRemoteVideo = (props) => {

  if (!props.src) {
    return null;
  }

  const { attr, src } = props;

  const videoId = url.parse(src, true).query.v;

  const opts = {
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  return(
    <div className={`media media--remote-video media--align-${attr['data-align'] || 'none'}`}>
      <YouTube videoId={videoId} opts={opts} />  
    </div>
  )
}

export default MediaRemoteVideo;

