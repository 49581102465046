import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import ReactHtmlParser from "react-html-parser";
import marked from "marked";
import sanitizeHtml from "sanitize-html";
import Media from "../media/media";

const Body = (props) => {

  const mediaItems = useStaticQuery(graphql`
    query mediaQuery {
      drupal {
        mediaQuery {
          entities {
            entityId
            entityBundle
            entityUuid
            ... on Drupal_MediaImage {
              mid
              uuid
              fieldMediaImage {
                mediaGatsbyFile {
                  publicURL
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                entity {
                  url
                }
              }
            }
            ... on Drupal_MediaVideo {
              mid
              uuid
              fieldMediaVideoFile {
                mediaGatsbyFile {
                  publicURL
                }
                entity {
                  url
                }
              }
            }
            ... on Drupal_MediaRemoteVideo {
              mid
              uuid
              fieldMediaOembedVideo
            }
          }
        }
      }
    }
  `);

  const processBody = (body) => {
    // First sanitize the html, allow our drupal-media tags with attributes.
    body = sanitizeHtml(marked(body), { 
      allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'drupal-media' ]),
      allowedAttributes: Object.assign({ 'drupal-media': ['data-*'] }, sanitizeHtml.defaults.allowedAttributes),
    });
    return ReactHtmlParser(body, {
      // Transform is used to transform our <drupal-media> tag into an actual
      // media item (image, video) provided by Gatsby.
      transform: (node) => {
        if (node.type === 'tag' && node.attribs['data-entity-type'] === 'media') {
          node.parent = null;
          let uuid = node.attribs['data-entity-uuid']
          for (let media of mediaItems.drupal.mediaQuery.entities) {
            if (media.uuid === uuid) {
              return <Media key={uuid} attr={node.attribs} media={media}/>
            }
          }
        }
        else {
          return undefined;
        }
      },
    })
  }
  
  return (
    <div className={props.className}>{processBody(props.children)}</div>
  )
};

export default Body;
